<template>
  <div id="app">
    <loading :active.sync="isLoading" loader="dots" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters(["isLoading"])
  }
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
