import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // loading
    isLoading: false,

    // 購物車內商品總數
    cartProductQuantity: 0,

    // 更新購物車
    updateCart: false
  },
  mutations: {
    // loading
    LOADING(state, status) {
      state.isLoading = status;
    },

    // 購物車內商品總數
    GET_CART_PRODUCT_QUANTITY(state, payload) {
      state.cartProductQuantity = payload;
    },

    // 更新購物車
    UPDATE_CART(state, payload) {
      state.updateCart = payload;
    },
  },
  actions: {
    // loading
    updateLoading({ commit }, payload) {
      commit("LOADING", payload);
    },
    
    // 更新購物車
    updateCart({ commit }, payload) {
      commit("UPDATE_CART", payload);
    },

    // 取得購物車內商品總數
    getCartProductQuantity({ commit }, payload) {
      commit("GET_CART_PRODUCT_QUANTITY", payload);
    }
  },
  getters: {
    // loading
    isLoading: state => state.isLoading,
    // 購物車內商品總數
    cartProductQuantity: state => state.cartProductQuantity,
    // 更新購物車
    updateCart: (state) => state.updateCart
  }
});
