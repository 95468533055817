import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layout/Layout"),
    children: [
      {
        name: "商品列表",
        path: "product",
        component: () => import("@/views/product/Product")
      },
      {
        name: "常購商品",
        path: "frequently-bought",
        component: () => import("@/views/product/FrequentlyBought")
      },
      {
        name: "歷史訂單",
        path: "order-history",
        component: () => import("@/views/order/OrderHistory")
      },
      {
        name: "訊息通知",
        path: "message",
        component: () => import("@/views/message/Message")
      },
      {
        name: "個人資訊",
        path: "information",
        component: () => import("@/views/information/Information")
      },
      {
        name: "推播管理",
        path: "push-message",
        component: () => import("@/views/push/PushMessage"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    name: "登入",
    path: "/login",
    component: () => import("@/views/login/Login")
  },
  {
    name: "選擇客戶", // for sales
    path: "/select-customer",
    component: () => import("@/views/login/SelectCustomer")
  },
  {
    name: "客戶報價",
    path: "/customer-quote",
    component: () => import("@/views/quote/CustomerQuote")
  },
  {
    name: "商品報價",
    path: "/product-quote",
    component: () => import("@/views/quote/ProductQuote")
  },
  {
    name: "報價完成",
    path: "/finish",
    component: () => import("@/views/quote/Finish")
  },
  {
    path: "*",
    redirect: "/login"
  }
];

const router = new VueRouter({
  routes
});

export default router;
