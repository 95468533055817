import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

// 設定CORS跨域並設定訪問許可權，允許跨域攜帶cookie資訊
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;

// jquery
import jquery from "jquery";
window.$ = jquery;

// dayjs
import dayjs from "dayjs";
Vue.prototype.dayjs = dayjs;

// loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("Loading", Loading);

// bootstrap 4
import "bootstrap";

// eva-icon
import EvaIcons from "vue-eva-icons";
Vue.use(EvaIcons);

// element-ui
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);

// broadcast-channel
import { BroadcastChannel } from "broadcast-channel";

// vee-validate
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, confirmed } from "vee-validate/dist/rules";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

extend("required", {
  ...required,
  message: "此欄位必填"
});
extend("confirmed", {
  ...confirmed,
  message: "確認密碼與新密碼不一致"
});

// 阻止vue在啟動時生成生產提示
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const channel = new BroadcastChannel("AUTH_CHANNEL");

  const saveUserInfo = data => {
    sessionStorage.setItem("PATH", data.path);
    sessionStorage.setItem("USER_INFO", JSON.stringify(data.userInfo));
  };

  const pushSignInRequest = () => {
    channel.postMessage({ action: "reqLogin", path: to.path });
    if (to.path !== "/login") next("/login");
    else next();
  };

  const receiveSiteMessage = e => {
    switch (e.action) {
      case "reqLogin":
        {
          const token = JSON.parse(sessionStorage.getItem("USER_INFO"));
          if (token !== null) {
            channel.postMessage({
              action: "resMember",
              path: e.path,
              userInfo: token
            });
          }
        }
        break;
      case "resMember":
        saveUserInfo(e);
        break;
    }
  };

  channel.onmessage = e => {
    receiveSiteMessage(e);
  };

  window.setTimeout(() => {
    if (sessionStorage.getItem("LOGOUT")) {
      channel.close();
      sessionStorage.removeItem("USER_INFO");
      if (to.path !== "/login") next("/login");
      else next();
    } else {
      const token = JSON.parse(sessionStorage.getItem("USER_INFO"));
      if (!token || token === "null") {
        pushSignInRequest();
      } else {
        const isAuthenticated = localStorage.PUSH_MESSAGE_PERMISSION;
        if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
          next({ path: "/login" });
        } else {
          next();
        }
        const path = sessionStorage.getItem("PATH");
        if (to.path === "/login") next(path);
        else next();
      }
    }
  }, 500);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
